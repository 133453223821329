<template>
	<div>
		<Order simple-text="order-online-paid" />
	</div>
</template>

<script>
	import Order from "../Order";
	import {EventBus} from "../../../event-bus";

	export default {
		name: "OrderOnlinePaid",
		metaInfo: {
			title: "Objednávka uhrazena",
			meta: [{
				name: "robots",
				content: "noindex"
			}]
		},
		components: {Order},
		mounted() {
			// Hide delivery modal should it be open
			EventBus.$emit("toggleDeliveryModal", false);
		}
	}
</script>